@import '../../../styles/media-query.scss';

.home {
  background-color: #fff;
  border-radius: 20px;
  height: 583px;
 height: calc(100vh - 80px);
  margin: -15px 0;
  padding: 50px;
 font-family: lato;


  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -28px;
    font-family: lato;  

h1 {
      font-size: 35px;
      font-family: lato;
    }

    .login {
      width: 120px;
      height: 43px;    
      border-radius: 45px;
      background-color: inherit;
      color: #E670CC;
      border: 2px solid #E670CC;
      outline: none;
      font-weight: 500;
      font-family: lato;


      &:hover {
        background-color: #ffe0f8;
        color: #E670CC;
      }
    }
  }

  .tabsHeader {
    display: flex;
    column-gap: 30px;
    align-items: center;
    margin: 7px 0 20px;
    font-family: lato;


    .tab {
      display: flex;
      column-gap: 10px;
      align-items: center;
      padding: 7px 3px;
      cursor: pointer;
      font-family: lato;


      .tabWriteIcon {
        width: 20px;
        height: 20px;
        font-family: lato;


        path {
          fill: #80848d;
        }
      }

      .tabHistoryIcon {
        width: 20px;
        height: 20px;
        font-family: lato;


        path {
          stroke: #80848d;
        }
      }

      label {
        color: #80848d;
        font-size: 17px;
        cursor: pointer;
        font-family: lato;

      }

      &.active {
        border-bottom: 2px solid #e670cc;
        font-family: lato;


        .tabWriteIcon {
          path {
            fill: #e670cc;
            font-family: lato;

          }
        }

        .tabHistoryIcon {
          path {
            stroke: #e670cc;
            font-family: lato;

          }
        }

        label {
          color: #e670cc;
          font-family: lato;

        }
      }
    }
  }
  
  .form {
    display: flex;
    flex-direction: column;
    padding-right: 10px;
    box-shadow: none;
    row-gap: 25px;
    font-family: lato;


    &Group {
      display: flex;
      flex-direction: column;
      padding: 10px;
      border-radius: 10px;
      background-color: #ffffff;
      font-family: lato;


      .form-label {
        font-weight: bold;
        margin-bottom: 5px;
        font-family: lato;

      }

      select {
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        font-family: lato;

        
        border-radius: 5px;
        outline: none;
        font-family: lato;

      }
    }

    // Specific styles for each customized box
    .analytical {
      border-radius: 10;
      font-family: lato;

    }

    .citation {
      font-family: lato;

    }

    .tone {
      font-family: lato;

    }

    .language {
      font-family: lato;

    }
  }

  .generateReply {
    margin-top: 50px;
    width: 250px;
    height: 45px;
    border-radius: 45px;
    background-color: #e670cc;
    color: #fff;
    border: none;
    outline: none;
    font-weight: 500;
    font-family: lato;


    &:hover {
      background-color: #ffe0f8;
      color: #e670cc;
      font-family: lato;

    }

    .arrowLongRight {
      margin-left: 50px;
      font-family: lato;

    }
  }
}

.dess {
  background-color: #ffffff;
  box-shadow: #80848d;
  width: 100%; 
  font-family: lato;
 
}

.ana {
  font-style: italic;
  font-family: lato;


}

.customSelect01 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5.70711 9.71069C5.31658 10.1012 5.31658 10.7344 5.70711 11.1249L10.5993 16.0123C11.3805 16.7927 12.6463 16.7924 13.4271 16.0117L18.3174 11.1213C18.708 10.7308 18.708 10.0976 18.3174 9.70708C17.9269 9.31655 17.2937 9.31655 16.9032 9.70708L12.7176 13.8927C12.3271 14.2833 11.6939 14.2832 11.3034 13.8927L7.12132 9.71069C6.7308 9.32016 6.09763 9.32016 5.70711 9.71069Z" fill="%230F0F0F"></path></svg>');
  background-repeat: no-repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 1.65em auto, 100%;
  font-family: lato;

}

.customSelect02 {
  -webkit-appearance: none;    
  -moz-appearance: none;
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5.70711 9.71069C5.31658 10.1012 5.31658 10.7344 5.70711 11.1249L10.5993 16.0123C11.3805 16.7927 12.6463 16.7924 13.4271 16.0117L18.3174 11.1213C18.708 10.7308 18.708 10.0976 18.3174 9.70708C17.9269 9.31655 17.2937 9.31655 16.9032 9.70708L12.7176 13.8927C12.3271 14.2833 11.6939 14.2832 11.3034 13.8927L7.12132 9.71069C6.7308 9.32016 6.09763 9.32016 5.70711 9.71069Z" fill="%230F0F0F"></path></svg>');
  background-repeat: no-repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 1.65em auto, 100%;
  font-family: lato;

}

.customSelect03 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5.70711 9.71069C5.31658 10.1012 5.31658 10.7344 5.70711 11.1249L10.5993 16.0123C11.3805 16.7927 12.6463 16.7924 13.4271 16.0117L18.3174 11.1213C18.708 10.7308 18.708 10.0976 18.3174 9.70708C17.9269 9.31655 17.2937 9.31655 16.9032 9.70708L12.7176 13.8927C12.3271 14.2833 11.6939 14.2832 11.3034 13.8927L7.12132 9.71069C6.7308 9.32016 6.09763 9.32016 5.70711 9.71069Z" fill="%230F0F0F"></path></svg>');
  background-repeat: no-repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 1.65em auto, 100%;
  font-family: lato;

}

.customSelect04 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5.70711 9.71069C5.31658 10.1012 5.31658 10.7344 5.70711 11.1249L10.5993 16.0123C11.3805 16.7927 12.6463 16.7924 13.4271 16.0117L18.3174 11.1213C18.708 10.7308 18.708 10.0976 18.3174 9.70708C17.9269 9.31655 17.2937 9.31655 16.9032 9.70708L12.7176 13.8927C12.3271 14.2833 11.6939 14.2832 11.3034 13.8927L7.12132 9.71069C6.7308 9.32016 6.09763 9.32016 5.70711 9.71069Z" fill="%230F0F0F"></path></svg>');
  background-repeat: no-repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 1.65em auto, 100%;
  font-family: lato;

}

@include small-screen {
  .home {
        padding: 10px;
     height: 100%;
    // margin: 100px 0 20px;
    margin: 100px 0 200px;
    font-family: lato;


    .header {
      flex-direction: column;
      font-family: lato;


      h1 {
        font-size: 30px;
        padding-top: 30px;
        font-family: lato;

      }

      .login {
        width: 100%;
        height: 40px;
        font-family: lato;

      }
    }

    .tabsHeader {
      column-gap: 15px;
      font-family: lato;


      .tab {
        column-gap: 15px;
        font-family: lato;


        label {
          // font-size: 15px;
        }
      }
    }

    .form {
      // row-gap: 15px;

      &Group {
        padding: 5px;
        font-family: lato;


        select {
          padding: 5px;
          font-family: lato;

        }
      }
    }
  }

  .customSelect01, .customSelect02, .customSelect03, .customSelect04 {
    width: 90%; // Adjust width for mobile view
    font-family: lato;

  }
}
.notLoggedInText {
  text-align: center;
  font-size: 19px;
  color: red;  // You can adjust the color as needed
  margin-top: 20px;  // Adjust the margin as needed
}
