@import '../../../styles/media-query.scss';

.buyPremium {
  width: 240px;
  height: 45px;
  border-radius: 50px;
  background-color: #E670CC;
  color: #fff;
  border: none;
  outline: none;
  font-weight: 500;
  text-align: left;
  padding: 10px 30px;
  font-family: lato;


  .crown {
    margin-right: 15px;
    width: 25px;
    height: 25px;
  }

  &:hover {
    background-color: #ffe0f8;
    color: #E670CC;
  }
}

.sidebarLinksContainer {
  margin-top: 50px;
  display: flex;
  row-gap: 15px;
  flex-direction: column;
  font-family: lato;


  .sidebarLink {
    display: flex;
    align-items: center;
    column-gap: 15px;
    padding: 8px 45px;
    cursor: pointer;
    font-family: lato;


    .linkIcon {
      width: 25px;
      height: 25px;
      font-family: lato;


      rect {
        fill: #80848D;
        font-family: lato;

      }
    }

    label {
      color: #80848D;
      font-size: 16px;
      cursor: pointer;
      font-family: lato;

    }

    &.active,
    &:hover {
      border-right: 3px solid #E670CC;

      .linkIcon {
        rect {
          fill: #E670CC;
        }

        path {
          fill: #E670CC;
        }
      }

      label {
        color: #E670CC;
      }
    }
  }


}

.desktopSidebarWrapper {
  background-color: #fff;
  border-radius: 20px;
  height: calc(100vh - 40px);
  display: block;
  font-family: lato;


  .aiAcademic {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0 30px;
    column-gap: 15px;
    font-family: lato;


    .aiAcademicLogo {
      width: 30px;
      height: 30px;
    }

    h2 {
      font-size: 24px;
      color: #E670CC;
      margin: 0;
      font-weight: 400;
      text-transform: uppercase;
      font-family: lato;

    }


  }
}

.mobileSidebarWrapper {
  display: none;
  font-family: lato;


  .mobileHeader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: fit-content;
    padding: 20px;
    background-color: #E670CC;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: lato;


    .iconButton {
      background-color: inherit;
      border: 0;
      outline: none;
      width: 30px;
      height: 30px;
      font-family: lato;

    }

    .rightContent {
      display: flex;
      align-items: center;
      column-gap: 20px;
      font-family: lato;

    }

    .loginButton {
      background-color: inherit;
      border: 0;
      outline: none;
      width: 100px;
      height: 40px;
      border-radius: 45px;
      background-color: inherit;
      color: #ffe0f8;
      border: 2px solid #ffe0f8;
      outline: none;
      font-weight: 500;
      font-family: lato;

    }
  }

  .mobileSidebar {
    position: fixed;
    background-color: #fff;
    z-index: 2;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    font-family: lato;


    .mobileSidebarHeader {
      padding: 20px;
      background-color: #E670CC;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: 20px;
      font-family: lato;


      h3 {
        margin: 0;
        font-size: 25px;
        color: #ffe0f8;
        font-family: lato;

      }
    }

    .closeButton {
      position: absolute;
      top: 20px;
      right: 20px;
      background-color: inherit;
      border: 0;
      outline: none;
      width: 30px;
      height: 30px;
      font-family: lato;

    }
  }
}

@include medium-screen {

  .buyPremium {
    width: calc(100% - 50px);
    margin: 25px 25px 0;
    font-family: lato;

  }

  .desktopSidebarWrapper {
    display: none;
    font-family: lato;

  }

  .mobileSidebarWrapper {
    display: block;
    font-family: lato;

  }
}