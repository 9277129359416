@mixin small-screen {
  @media (max-width: 600px) {
    @content;
  }
}
.showcontent{
  background-color: #ffffff;
  border-radius: 20px;
  height: calc(100vh - 80px);
  margin: 20px 0;
  padding: 50px;
  overflow-y: auto;
  position: relative;
  font-family: lato;
  font-size: calc(0.6rem + .7vw);
}
.replyBoxContainer {
  background-color: #ffffff;
  border-radius: 20px;
  height: calc(100vh - 80px);
  margin: 20px 0;
  padding: 50px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  text-align: justify;
  font-family: lato;
  font-size: xx-small;
  font-size: calc(0.6rem + .7vw);
}

.buttonContainer {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  font-family: lato;

  padding: 10px;
}

.icon {
  color: #061627;
  font-size: 20px;
  margin: 0 10px;
  cursor: pointer;
  font-family: lato;
  transition: color 0.4s;

  &:hover {
    color: #020a13;
  }
}
.backIcon {
  font-size: 20px;
  color: #ee07b4;
  cursor: pointer;
  transition: color 0.4s;
  font-family: lato;


  // &:hover {
  //   color: #ee07b4;
  // }
}
.backText {
  margin-left: 10px;
  font-size: 22px;
  color: #ee07b4;
  font-family: 'Lato'; 

}
.backContainer {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  position: relative; 
  font-family: lato;

}

@include small-screen {
  .replyBoxContainer {
    background-color: #ffffff;
    border-radius: 20px;
    height: calc(100vh - 80px);
    margin: -56px 0;
    padding: 20px;
    //position: fixed;
    font-family: lato;
    font-size: calc(0.6rem + .7vw);
  }
}