input,
select,
textarea {
  border: 2px solid #E6E8EC;
  border-radius: 10px;
  padding: 10px 16px;
  background-color: inherit;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  outline: none;
  width: calc(100% - 32px);
  color: #777E90;

  &:placeholder {
    color: #B1B5C3;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:focus,
  &:hover {
    border-color: #B1B5C3;
  }

}

.form-group {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  width: 100%;
}

.form-label {
  font-size: 13px;
  margin-bottom: 6px;
  color: #B1B5C3;
  text-transform: uppercase;
  font-weight: 500;
}