.spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  position: fixed;   
  top: 0;
  left: 20px;
}

.spinner .lottie {
  width: 250px; /* Adjust the width to make the animation smaller */
  height: 100px; /* Adjust the height to make the animation smaller */
}
