@mixin small-screen {
  @media (max-width: 768px) {
    @content;
  }
}

@include small-screen {
  .history {
    background-color: #ffffff;
    padding: 25px;
    margin: -95px 0;
    border-radius: 10px;
    height: calc(100vh - 80px);
  }
}

.history {
  background-color: #ffffff;
  border-radius: 20px;
 // height: 700px;
  margin: -15px 0;
  display: flex;
  flex-direction: column;
  font-family: Lato;

  h2 {
    margin-bottom: 20px;
    color: #ee07b4;
    font-family: Lato;
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      border: 1px solid #dbd7d7;
      padding: 20px;
      border-radius: 10px;
      margin-bottom: 10px;
      font-family: Lato;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative; /* For positioning the buttons */

      span {
        display: -webkit-box;
        -webkit-line-clamp: 2; /* Limit to 2 lines */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal; /* Make sure content can wrap */
        margin-right: 20px;
        max-width: calc(100% - 40px); /* Adjust according to the width of the buttons */
      }

      .buttons {
        display: flex;
        gap: 10px;
        opacity: 0; /* Make buttons invisible by default */
        transition: opacity 0.3s ease; /* Smooth transition */

        button {
          background: none;
          border: none;
          cursor: pointer;
          font-size: 18px;
          padding: 5px;
          color: #777;

          &:hover {
            color: #3b3939;
          }

          &:focus {
            outline: none;
          }
        }
      }

      &:hover .buttons {
        opacity: 1; /* Show buttons on hover */
      }

      p {
        margin: 5px 0;
        font-size: 14px;
        color: #777;
        font-family: Lato;
      }
    }
  }
}