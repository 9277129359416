@import '../../../styles/media-query.scss';

.privacyPolicy {
  background-color: #fff;
  border-radius: 20px;
  //height: 583px;
  height: calc(100vh - 80px);
  margin: -15px 0;
  padding: 50px;
  overflow-y: auto;
  font-family: lato;


  .content {
    color: #3f4f64;
    font-family: lato;

  }
    
  .h2 {
    color: #E670CC;
    font-family: lato;

  }
}

@include medium-screen {
  .privacyPolicy {
    height: 100%;
    margin: 100px 0 20px;
    font-family: lato;

  }
}