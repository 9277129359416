@mixin small-screen {
  @media (max-width: 500px) {
    @content;
  }
}

@mixin mini-screen {
  @media (max-width: 700px) {
    @content;
  }
}

@mixin medium-screen {
  @media (max-width: 900px) {
    @content;
  }
}

@mixin large-screen {
  @media (max-width: 1200px) {
    @content;
  }
}