@import './styles/media-query.scss';

.appLayout {
  background: url(./assets/images/background-image.png) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
  height: fit-content;
  padding: 20px;
  display: grid;
  grid-template-columns: 300px auto;
  column-gap: 40px;
  align-items: center;
}

@include medium-screen {
  .appLayout {
    grid-template-columns: 100%;
    column-gap: 0;
  }
}